<template>
    <div>
        <br />
        <h1 class="text-xs-center">SmartTransit Staff</h1>
        <staff-management
            :signed-in-user="signedInUser"
        />
    </div>
</template>

<script>
import src from './src'
export default src
</script>
