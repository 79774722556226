// import store from '../../store'
// import { D_FIND_TRANSPORTATION_OWNERS } from '../../utilities/action-types'
// import { C_ADD_SITE_ALERT } from '../../utilities/mutation-types'
// import _ from 'lodash'

export default {
  name: 'staff',
  props: {
    signedInUser: Object
  },
  data () {
    return {}
  }
}
